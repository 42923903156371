import { AnyAction } from 'redux';
import { FUNDPAGE_REPORT_GET_SUCCESS, FUNDPAGE_REPORT_GET_FAILED, SEARCHPAGE_ETFS_GET_SUCCESS } from 'src/actions/etfs';
import { ETFSearchResult } from 'src/model/ETFSearchResult';

const initialState: {
    fundPage: {
        report?: any,
        loadSuccess?: boolean
    },
    searchPage: {
        etfs?: ETFSearchResult[]
    }
} = {
    fundPage: {
    },
    searchPage: {
    }
};

const rootReducer = (state = initialState, action: AnyAction) => {
    if (action.type === FUNDPAGE_REPORT_GET_SUCCESS) {
        return Object.assign({}, state, {
            fundPage: Object.assign({}, state.fundPage, {
                report: action.report,
                loadSuccess: true
            })
        });
    } else if (action.type === FUNDPAGE_REPORT_GET_FAILED) {
        return Object.assign({}, state, {
            fundPage: Object.assign({}, state.fundPage, {
                loadSuccess: false
            })
        });
    } else if (action.type === SEARCHPAGE_ETFS_GET_SUCCESS) {
        return Object.assign({}, state, {
            searchPage: Object.assign({}, state.searchPage, {
                etfs: action.etfs
            })
        });
    }
    return state;
};

export default rootReducer;