import { call, put, select, takeLatest } from 'redux-saga/effects'
import { FUNDPAGE_REPORT_GET_SUCCESS, FUNDPAGE_REPORT_GET_FAILED, FUNDPAGE_REPORT_GET_REQUEST, SEARCHPAGE_ETFS_GET_FAILED, SEARCHPAGE_ETFS_GET_REQUEST, SEARCHPAGE_ETFS_GET_SUCCESS } from 'src/actions/etfs';
import { ETFFullReport } from 'src/fundpage/ETFFullReport';
import { ETFSearchResult } from 'src/model/ETFSearchResult';
import { ETFService } from 'src/services/ETFService';

function* fetchETFs() {
    try {
        const service = new ETFService();
        // Prevent unnecessary access to the browser cache.
        let etfs: ETFSearchResult[] | undefined = yield select(state => state.searchPage.etfs);
        if (!etfs) {
            etfs = yield call(service.getETFs);
        }
        if (!etfs) {
            etfs = [];
        }
        yield put({ type: SEARCHPAGE_ETFS_GET_SUCCESS, etfs: etfs });
    } catch (e) {
        yield put({ type: SEARCHPAGE_ETFS_GET_FAILED, message: (e as any).message });
    }
}

function* fetchReport(action: any) {
    try {
        const service = new ETFService();
        const report: ETFFullReport = yield call(service.getReport, action.in_etf_id!);
        yield put({ type: FUNDPAGE_REPORT_GET_SUCCESS, report: report });
    } catch (e) {
        yield put({ type: FUNDPAGE_REPORT_GET_FAILED, message: (e as any).message });
    }
}

export function* fetchETFsSaga() {
    yield takeLatest(SEARCHPAGE_ETFS_GET_REQUEST, fetchETFs);
}

export function* fetchReportSaga() {
    yield takeLatest(FUNDPAGE_REPORT_GET_REQUEST, fetchReport);
}

