import axios from 'axios';
import { ETFFullReport } from 'src/fundpage/ETFFullReport';
import { ETFSearchResult } from 'src/model/ETFSearchResult';

export class ETFService {
    async getETFs(): Promise<ETFSearchResult[]> {
        const response = await axios.get(process.env.REACT_APP_CATALOG_URL!);
        return response.data.map((etf: ETFSearchResult) => {
            etf.id = etf.in_etf_id;
            return etf;
        });
    }

    async getReport(in_etf_id: string): Promise<ETFFullReport> {
        const response = await axios.get(process.env.REACT_APP_REPORT_URL! + in_etf_id);
        return new ETFFullReport(response.data);
    }
}
