import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import { memo } from "react";
import Typography from "@mui/material/Typography";
import { INETFLogo } from './inETFLogo';

export const NavBar = memo(() => {
    // We lower the line height and add some padding top because the font probably accounts for letters such as 'g'
    // and leaves some space below the baseline which we don't need.
    return (
        <header>
            <AppBar position="static" color="secondary">
                <Toolbar disableGutters={true} variant="dense" sx={{ height: 45, paddingTop: 3.5, paddingBottom: 3.5, paddingLeft: "16px", paddingRight: "16px" }}>
                    <Link to="/" style={{ marginRight: 10, textDecoration: 'none' }}>
                        <INETFLogo sx={{ width: 45, height: 45 }} />
                    </Link>
                    <Link to="/" style={{ lineHeight: 1, textDecoration: 'none' }}>
                        <Typography sx={{
                                lineHeight: "45px",
                                paddingTop: "10px",
                                fontSize: 40,
                                fontWeight: 500
                            }} color="primary.dark">inETF
                        </Typography>
                    </Link>
                </Toolbar>
            </AppBar>
        </header>
    );
});

export default NavBar;