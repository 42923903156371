import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { memo } from "react";
import Typography from "@mui/material/Typography";
import { INETFLogo } from './inETFLogo';

export const Footer = memo(() => {
  // marginLeft: -5px because the logo is slightly "off-left", which looks good normally, but doesn't in this case because it should align with the text below.
  return (
    <footer>
      <Box position="static" bgcolor="primary.light" color="white" sx={{
        minHeight: 300
      }}>
        <Container sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingTop: "28px",
          paddingBottom: "28px"
        }}>
          <INETFLogo sx={{ marginLeft: "-5px", stroke: "white", fill: "white", width: 100, height: 100 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" color="inherit">
                inETF
                <br />
                powered by <Link target="_blank" rel="noopener" href="https://cinnamon.bmpi.ch/" color="inherit">Cinnamon Reporting</Link>
                <br />
                <br />
                bmpi AG
                <br />
                Militärstrasse 76
                <br />
                8004 Zürich
                <br />
                Schweiz
                <br />
                +41 44 454 84 84
                <br />
                <Link href="mailto:info@bmpi.ch" color="inherit">info@bmpi.ch</Link>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" color="white" display="block">Disclaimer</Typography>
              <Typography sx={{ fontSize: 10, marginBottom: 2.5 }} color="white" display="block">The information contained in this website is for demo and testing purposes only. The information is provided by inETF and while we endeavour to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk. In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website. Through this website you are able to link to other websites which are not under the control of inETF. We have no control over the nature, content and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them. Every effort is made to keep the website up and running smoothly. However, inETF takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.</Typography>
              <Typography variant="body2" color="white" display="block">© 2022 bmpi AG. All rights reserved.</Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </footer >
  );
});

export default Footer;