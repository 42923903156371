"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mergeDeep = exports.mapToJson = exports.optValueFromJson = exports.valueFromJson = exports.optEnumFromJson = exports.enumFromJson = exports.optObjectFromJson = exports.objectFromJson = exports.optBooleanArrayFromJson = exports.booleanArrayFromJson = exports.optNumberArrayFromJson = exports.numberArrayFromJson = exports.optStringArrayFromJson = exports.stringArrayFromJson = exports.optArrayFromJson = exports.arrayFromJson = exports.dateToJson = exports.optDateFromJson = exports.dateFromJson = exports.optBooleanFromJson = exports.booleanFromJson = exports.optStringFromJson = exports.stringFromJson = exports.optNumberFromJson = exports.numberFromJson = exports.jsonObfuscate = exports.jsonFilterOut = void 0;
function jsonFilterOut(obj, excludeKeys, excludeNulls = false) {
    const res = {};
    for (const key of Object.keys(obj))
        if (!excludeKeys[key])
            if (obj[key] !== null || !excludeNulls)
                res[key] = obj[key];
    return res;
}
exports.jsonFilterOut = jsonFilterOut;
function jsonObfuscate(obj, paramPattern) {
    const res = {};
    for (const [key, value] of Object.entries(obj)) {
        if (value !== undefined) {
            if (Object.keys(paramPattern).includes(key))
                res[key] = paramPattern[key];
            else if (Array.isArray(value))
                res[key] = value.map(i => i instanceof Object ? jsonObfuscate(i, paramPattern) : i);
            else if (value instanceof Object) {
                //force executing toJSON() here
                const resolved = JSON.parse(JSON.stringify(value));
                res[key] = jsonObfuscate(resolved, paramPattern);
            }
            else
                res[key] = value;
        }
    }
    return res;
}
exports.jsonObfuscate = jsonObfuscate;
function numberFromJson(obj) {
    if (typeof obj === 'number')
        return obj;
    throw new Error(`${JSON.stringify(obj)} is not a number`);
}
exports.numberFromJson = numberFromJson;
function optNumberFromJson(obj) {
    return obj === undefined ? obj : numberFromJson(obj);
}
exports.optNumberFromJson = optNumberFromJson;
function stringFromJson(obj, allowed) {
    if (typeof obj === 'string') {
        if (allowed && allowed.indexOf(obj) < 0)
            throw new Error(`${JSON.stringify(obj)} is not one of ${allowed}`);
        return obj;
    }
    throw new Error(`${JSON.stringify(obj)} is not a string`);
}
exports.stringFromJson = stringFromJson;
function optStringFromJson(obj, allowed) {
    return obj === undefined ? obj : stringFromJson(obj, allowed);
}
exports.optStringFromJson = optStringFromJson;
function booleanFromJson(obj) {
    if (typeof obj === 'boolean')
        return obj;
    throw new Error(`${JSON.stringify(obj)} is not a boolean`);
}
exports.booleanFromJson = booleanFromJson;
function optBooleanFromJson(obj) {
    return obj === undefined ? obj : booleanFromJson(obj);
}
exports.optBooleanFromJson = optBooleanFromJson;
function dateFromJson(obj) {
    if (obj instanceof Date)
        return obj;
    // instanceof may fail (different class object) when coming e.g. from oracledb
    if (obj.getTime !== undefined)
        return new Date(obj.getTime());
    if (typeof obj === 'string') {
        const time = Date.parse(obj.length === 10 ? obj + "T00:00:00Z" : obj); // Cinnamon convention
        if (!isNaN(time))
            return new Date(time);
    }
    throw new Error(`${obj.constructor ? obj.constructor.name : 'Value'} ${JSON.stringify(obj)} is not a Date`);
}
exports.dateFromJson = dateFromJson;
function optDateFromJson(obj) {
    return obj === undefined ? obj : dateFromJson(obj);
}
exports.optDateFromJson = optDateFromJson;
function dateToJson(date) {
    return date.toISOString().substr(0, 10); // Cinnamon convention
}
exports.dateToJson = dateToJson;
function arrayFromJson(obj) {
    if (Array.isArray(obj))
        return obj;
    throw new Error(`${JSON.stringify(obj)} is not an Array`);
}
exports.arrayFromJson = arrayFromJson;
function optArrayFromJson(obj) {
    return obj === undefined ? obj : arrayFromJson(obj);
}
exports.optArrayFromJson = optArrayFromJson;
function stringArrayFromJson(obj) {
    if (Array.isArray(obj)) {
        const res = [];
        for (let s of obj)
            res.push(stringFromJson(s));
        return res;
    }
    throw new Error(`${JSON.stringify(obj)} is not an Array`);
}
exports.stringArrayFromJson = stringArrayFromJson;
function optStringArrayFromJson(obj) {
    return obj === undefined ? obj : stringArrayFromJson(obj);
}
exports.optStringArrayFromJson = optStringArrayFromJson;
function numberArrayFromJson(obj) {
    if (Array.isArray(obj)) {
        const res = [];
        for (let s of obj)
            res.push(numberFromJson(s));
        return res;
    }
    throw new Error(`${JSON.stringify(obj)} is not an Array`);
}
exports.numberArrayFromJson = numberArrayFromJson;
function optNumberArrayFromJson(obj) {
    return obj === undefined ? obj : numberArrayFromJson(obj);
}
exports.optNumberArrayFromJson = optNumberArrayFromJson;
function booleanArrayFromJson(obj) {
    if (Array.isArray(obj)) {
        const res = [];
        for (let s of obj)
            res.push(booleanFromJson(s));
        return res;
    }
    throw new Error(`${JSON.stringify(obj)} is not an Array`);
}
exports.booleanArrayFromJson = booleanArrayFromJson;
function optBooleanArrayFromJson(obj) {
    return obj === undefined ? obj : booleanArrayFromJson(obj);
}
exports.optBooleanArrayFromJson = optBooleanArrayFromJson;
function objectFromJson(obj) {
    if (typeof obj === 'object')
        return obj;
    throw new Error(`${JSON.stringify(obj)} is not an object`);
}
exports.objectFromJson = objectFromJson;
function optObjectFromJson(obj) {
    return obj === undefined ? obj : objectFromJson(obj);
}
exports.optObjectFromJson = optObjectFromJson;
function enumFromJson(obj, enumType) {
    const enumAsAny = enumType;
    if ((typeof obj === 'number' || typeof obj === 'string') && obj in enumType)
        return enumAsAny[obj];
    // strings enums may be different from the symbol
    if (typeof obj === 'string')
        for (let key in enumAsAny) {
            const value = enumAsAny[key];
            if (value === obj || typeof value === 'string' && value.toLowerCase() === obj.toLowerCase())
                return enumAsAny[key];
        }
    throw new Error(`${JSON.stringify(obj)} is not a valid element of type ${JSON.stringify(enumType)}`);
}
exports.enumFromJson = enumFromJson;
function optEnumFromJson(obj, enumType) {
    return obj === undefined ? obj : enumFromJson(obj, enumType);
}
exports.optEnumFromJson = optEnumFromJson;
function valueFromJson(obj) {
    if (obj === null)
        return null;
    switch (typeof obj) {
        case 'number':
            return obj;
        case 'string':
            if (obj.match(/^[12]\d\d\d-\d\d-\d\d(T\d\d:\d\d:\d\d\.\d\d\dZ)?$/)) {
                const time = Date.parse(obj);
                if (!isNaN(time))
                    return new Date(time);
            }
            return obj;
        case 'boolean':
            return obj;
        case 'object':
            if (obj instanceof Date)
                return obj;
            break;
    }
    throw new Error(`${JSON.stringify(obj)} is not a valid value`);
}
exports.valueFromJson = valueFromJson;
function optValueFromJson(obj) {
    return obj === undefined ? obj : valueFromJson(obj);
}
exports.optValueFromJson = optValueFromJson;
function mapToJson(map) {
    const obj = {};
    map.forEach((value, key) => {
        obj[key] = value;
    });
    return obj;
}
exports.mapToJson = mapToJson;
/**
* Performs a deep merge of objects and returns new object. Does not modify
* objects (immutable) and merges arrays via concatenation.
*
* @param {...object} objects - Objects to merge
* @returns {object} New object with merged key/values
*/
function mergeDeep(...objects) {
    const isObject = (obj) => obj && typeof obj === 'object';
    return objects.reduce((result, current) => {
        Object.keys(current).forEach(key => {
            if (current[key] == null) {
                delete result[key];
            }
            else if (Array.isArray(result[key]) && Array.isArray(current[key])) {
                result[key] = result[key].concat(...current[key]);
            }
            else if (isObject(result[key]) && isObject(current[key])) {
                result[key] = mergeDeep(result[key], current[key]);
            }
            else {
                result[key] = current[key];
            }
        });
        return result;
    }, {});
}
exports.mergeDeep = mergeDeep;
