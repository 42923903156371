import './App.css';
import { NavBar } from './common/NavBar';
import { Footer } from './common/Footer';
import { applyMiddleware, createStore } from 'redux';
import sagas from './sagas';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { lazy, Suspense } from 'react';
import createTheme from '@mui/material/styles/createTheme';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { SiteSpinner } from './common/SiteSpinner';

const bmpiCookie = "inetfCookieAllowed";
function bmpiCookiesAllowed() {
  const allCookies = document.cookie.split(";");
  const cookie = allCookies.find(cookie => cookie.trim().split("=")[0] === bmpiCookie);
  if (cookie !== undefined) {
    const val = cookie.split("=")[1];
    if (["true", "false"].indexOf(val) > -1) {
      return val === "true";
    }
  }
  return undefined;
}
function setBmpiCookiesDecision(decision: boolean) {
  if (typeof decision === "boolean") {
    document.cookie = `${bmpiCookie}=${decision}; max-age=31536000; Secure; SameSite=Strict`
  }
}
if (bmpiCookiesAllowed() === true) {
  (window as any)['ga-disable-MEASUREMENT_ID'] = false;
  (window as any).gtag('js', new Date());
  (window as any).gtag('config', 'G-DF85PJ4YK5');
}

function updateHideCookieConsent() {
  (document.getElementById("inetf-cookie-consent") as HTMLDivElement).hidden = bmpiCookiesAllowed() !== undefined;
}

updateHideCookieConsent();

const acceptCookiesElement = document.getElementById("inetf-acceptCookies");
if (acceptCookiesElement) {
  acceptCookiesElement.onclick = function () {
    setBmpiCookiesDecision(true);
    (window as any)['ga-disable-MEASUREMENT_ID'] = false;
    (window as any).gtag('js', new Date());
    (window as any).gtag('config', 'G-DF85PJ4YK5');
    updateHideCookieConsent();
  }
}
const declineCookies = document.getElementById("inetf-declineCookies");
if (declineCookies) {
  declineCookies.onclick = function () {
    setBmpiCookiesDecision(false);
    updateHideCookieConsent();
  }
}



const MainPage = lazy(() => import('./mainpage/MainPage'));
const SearchPage = lazy(() => import('./searchpage/SearchPage'));
const FundPage = lazy(() => import('./fundpage/FundPage'));
const NotFoundPage = lazy(() => import('./notfound/NotFoundPage'));

const theme = createTheme({
  palette: {
    primary: {
      main: '#59358C', // purple
      light: '#ff5056', // purple highlight
      dark: '#6d3cff' // coral
    },
    secondary: {
      main: '#2a1305', // brown
      light: '#e1e1e6', // light gray
      dark: '#BEA587' // beige
    },
  },
  typography: {
    fontFamily: 'Rubik, sans-serif',
    body2: {
      fontWeight: 500,
    }
  }
});

const sagaMiddleware = createSagaMiddleware()
export const store = createStore(
  rootReducer,
  applyMiddleware(sagaMiddleware)
)
sagaMiddleware.run(sagas);


export const App: React.FunctionComponent = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <CssBaseline />
          <NavBar />
          <HelmetProvider>
            <main>
              <Container style={{ marginTop: 20, marginBottom: 20, minHeight: 300 }}>
                <Routes>
                  <Route path="search" element={
                    <Suspense fallback={<SiteSpinner description="Loading search page" />}>
                      <SearchPage />
                    </Suspense>
                  } />
                  <Route path="fund/:id" element={
                    <Suspense fallback={<SiteSpinner description="Loading fund report" />}>
                      <FundPage />
                    </Suspense>
                  } />
                  <Route path="/" element={
                    <Suspense fallback={<SiteSpinner description="Loading main page" />}>
                      <MainPage />
                    </Suspense>
                  } />
                  <Route path="*" element={
                    <Suspense fallback={<SiteSpinner description="Loading not found page" />}>
                      <NotFoundPage />
                    </Suspense>
                  } />
                </Routes>
              </Container>
            </main>
          </HelmetProvider>
          <Footer />
        </Router>
      </ThemeProvider>
    </Provider >
  );
}

export default App;
