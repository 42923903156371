
import Box from '@mui/material/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { memo } from 'react';

interface ISiteSpinnerProps {
    description: string;
}

export const SiteSpinner = memo((props: ISiteSpinnerProps) => {
    return (<Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
    >
        <CircularProgress color="primary" aria-describedby={props.description} aria-busy={true} />
    </Box>);
});