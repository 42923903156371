import SvgIcon from "@mui/material/SvgIcon";
import { memo, useMemo } from 'react';

export const INETFLogo = memo((props: any) => {
    const stroke = useMemo(() => {
        return props.sx && props.sx.stroke ? props.sx.stroke : "#6d3cff";
    }, [props.sx]);
    const fill = useMemo(() => {
        return props.sx && props.sx.fill ? props.sx.fill : "#6d3cff";
    }, [props.sx]);
    return (
        <SvgIcon {...props}>
            <svg strokeWidth={0.5} stroke={stroke} fill={fill} version="1.2" baseProfile="tiny-ps" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path id="Shape 1" d="m1.59 16.17h3.75v6.52h-3.75v-6.52z" />
                <path id="Shape 2" d="m7.5 12.14h3.75v10.55h-3.75v-10.55z" />
                <path id="Shape 3" d="m13.41 13.31h3.75v9.38h-3.75v-9.38z" />
                <path id="Shape 4" d="m19.31 10.97h3.75v11.72h-3.75v-11.72z" />
                <path id="Shape 5" d="m1.21 12.34l-0.15-0.18l6.87-5.84l0.15 0.18l-6.87 5.84z" />
                <path id="Shape 6" d="m7.93 6.75l0.17-0.16l3.89 3.94l-0.17 0.16l-3.89-3.94z" />
                <path id="Shape 7" d="m11.79 10.6l-0.14-0.18l6.19-4.83l0.14 0.18l-6.19 4.83z" />
                <path id="Shape 8" d="m20.93 2.87l-1.32 4.12l-2.91-3.21l4.23-0.92z" />
            </svg>
        </SvgIcon>
    );
});