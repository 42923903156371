import { dateFromJson, JsonObject } from "ngr-core-api/out/JsonIO/JsonObject";
import { AllowedLanguage } from "ngr-core-api/out/Utilities/PortalUtilities";

export class ETFFullReport {
    readonly language: AllowedLanguage;
    private readonly reportTypeId: string;
    private readonly reportingDate: Date;
    private readonly availableLanguages: AllowedLanguage[] = [];
    private readonly portfolioId: string;

    constructor(public readonly data: any) {
        this.language = this.data.executionParameters.reportLanguage;
        this.reportTypeId = this.data.reportTypeId;
        this.reportingDate = dateFromJson((this.data.executionParameters as JsonObject).reportingDate);
        this.availableLanguages = (this.data.executionParameters as JsonObject).availableLanguages as AllowedLanguage[];
        this.portfolioId = (this.data.executionParameters as JsonObject).portfolioId as string;
    }

    public get chapterTitles(): string[] {
        return ["Fund"];
    }

    public getChapterIdFromTitle(title: string): string | undefined {
        return "Fund";
    }

    public getChapterTitleFromId(id: string) {
        return "Fund";
    }

    public get executionId(): string {
        return this.data.executionId;
    }
}